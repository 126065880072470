<template>
    <v-container
        :class="{ 'd-flex': $vuetify.breakpoint.mdAndUp }"
        style="height: 100vh"
    >
        <v-row align="center">
            <v-col class="d-flex justify-center">
                <login-form v-show="!isRedirecting" :next-url="nextUrl" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import LoginForm from '@/components/LoginForm';
import { Watch } from '@/utils/decorators';

const LoginProps = Vue.extend({
    name: 'Login',
    props: {
        nextUrl: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component({
    components: {
        LoginForm
    },
    computed: {
        ...mapGetters('user', ['hasActiveSession'])
    }
})
export default class Login extends LoginProps {
    hasActiveSession!: boolean;

    isRedirecting = false;
    redirectTo = '/welcome';

    @Watch('hasActiveSession')
    onActiveSession() {
        if (this.hasActiveSession) {
            this.isRedirecting = true;

            this.$router.replace(this.nextUrl || this.redirectTo);
        }
    }
}
</script>
