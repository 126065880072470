<template>
    <v-card
        elevation="2"
        class="ampifire-login"
        :class="{
            'pa-16': $vuetify.breakpoint.mdAndUp,
            'pa-8': $vuetify.breakpoint.smAndDown
        }"
    >
        <v-img
            v-if="isDefaultLogo"
            :alt="logo.alt"
            src="@/assets/img/logo.ampifire.com.svg"
            max-height="58"
            max-width="240"
            class="mb-4 mx-auto"
        />
        <v-img
            v-else
            :src="logo.src"
            :alt="logo.alt"
            max-height="58"
            max-width="240"
            class="mb-4 mx-auto"
        />
        <Transition mode="out-in" name="fade-in">
            <h1
                :key="heading"
                class="text-h5 font-weight-bold my-8 text-center"
            >
                {{ heading }}
            </h1>
        </Transition>
        <v-window v-model="action" vertical>
            <v-window-item :value="1">
                <v-container>
                    <v-row>
                        <v-text-field
                            v-model="login"
                            label="Username"
                            name="login"
                            filled
                            autofocus
                            autocomplete="off"
                            :disabled="isLoading"
                            @keyup.enter="tryLogin"
                        />
                    </v-row>

                    <v-row>
                        <v-text-field
                            v-model="password"
                            label="Password"
                            name="password"
                            filled
                            autocomplete="new-password"
                            :append-icon="passwordIcon"
                            :type="passwordInputType"
                            :disabled="isLoading"
                            @click:append="togglePasswordVisibility"
                            @keyup.enter="tryLogin"
                        />
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="6" class="pa-0">
                            <v-checkbox
                                v-model="autoLogin"
                                label="Keep me logged in"
                                :disabled="isLoading"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            md="6"
                            class="pa-0 d-flex justify-end align-self-center"
                        >
                            <a data-test="reset" @click.prevent="showReset">
                                Forgot your password?
                            </a>
                        </v-col>
                    </v-row>

                    <v-row class="pt-4">
                        <v-btn
                            class="primary"
                            block
                            x-large
                            :loading="isLoading"
                            @click="tryLogin"
                        >
                            Log In
                        </v-btn>
                    </v-row>
                </v-container>
            </v-window-item>
            <v-window-item :value="2">
                <v-container>
                    <v-row>
                        <v-text-field
                            v-model="email"
                            label="Email"
                            name="email"
                            filled
                            autofocus
                            :error-messages="errors.email"
                            :disabled="isLoading"
                        />
                    </v-row>
                    <v-row class="pt-4">
                        <v-btn
                            class="primary"
                            block
                            x-large
                            :loading="isLoading"
                            @click="tryReset"
                        >
                            Send Reset Link
                        </v-btn>
                    </v-row>
                    <v-row class="justify-center pt-6">
                        <a @click.prevent="showLogin">Cancel</a>
                    </v-row>
                </v-container>
            </v-window-item>
        </v-window>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

enum Actions {
    LogIn = 1,
    Reset = 2
}

const LoginFormProps = Vue.extend({
    name: 'LoginForm',
    props: {
        nextUrl: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component({
    computed: {
        ...mapGetters('user', ['hasActiveSession']),
        ...mapGetters('logo', ['logo'])
    }
})
export default class LoginForm extends LoginFormProps {
    hasActiveSession!: boolean;
    logo!: { src: string; alt: string };

    action = Actions.LogIn;

    showPassword = false;
    login = '';
    password = '';
    email = '';

    autoLogin = true;
    isLoading = false;

    isDefaultLogo = true;

    errors = {
        email: ''
    };

    get passwordIcon() {
        return this.showPassword ? 'eye-slash' : 'eye';
    }

    get passwordInputType() {
        return this.showPassword ? 'text' : 'password';
    }

    get heading() {
        return this.action === Actions.LogIn ? 'Log In' : 'Reset Password';
    }

    created() {
        this.getLogo();
    }

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    async getLogo() {
        this.$http.get('/users/get_logo').then(({ data }) => {
            this.isDefaultLogo = `${data.data?.logo?.file}`.includes(
                'logo.ampifire.com.svg'
            );

            this.$store.commit('logo/update', {
                src: data.data?.logo?.file,
                alt: data.data?.site
            });
        });
    }

    async tryLogin() {
        this.isLoading = true;

        this.authenticate()
            .catch(this.notifyFailed.bind(this))
            .finally(() => {
                this.isLoading = false;
            });
    }

    async authenticate() {
        return this.$store.dispatch('user/login', {
            login: this.login,
            password: this.password,
            autoLogin: this.autoLogin,
            nextUrl: this.nextUrl
        });
    }

    notifyFailed(error: string) {
        this.$store.dispatch('notification/error', error);
    }

    async tryReset() {
        this.isLoading = true;

        this.errors.email = '';

        return this.$store
            .dispatch('user/reset', {
                email: this.email
            })
            .then(() => {
                this.$store.dispatch(
                    'notification/success',
                    'Reset instructions sent. Please check your email.'
                );

                this.showLogin();
            })
            .catch((error: string) => {
                this.errors.email = error;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    showLogin() {
        this.action = Actions.LogIn;
        this.email = '';
    }

    showReset() {
        this.action = Actions.Reset;
    }
}
</script>

<style lang="scss" scoped>
.ampifire-login::v-deep {
    background-color: $blueish !important;
    border-top-style: solid !important;
    border-top-width: 0.25em !important;
    border-top-color: $primary-color !important;
    width: 500px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;

    .v-text-field--filled .v-input__slot {
        background-color: $white !important;

        input {
            filter: none;
            color: $secondary-color;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-internal-autofill-previewed,
    input:-internal-autofill-selected {
        filter: none;
        border: none;
        -webkit-text-fill-color: $secondary-color;
        -webkit-box-shadow: 0 0 0 100px white inset !important;
        transition: inherit;
        background-color: transparent !important;
    }
}

.fade-in-enter-active {
    transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
    transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
    opacity: 0;
}
</style>
